import SkeletonLoader from 'components/SkeletonLoader';
import { Suspense } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PermissionRoute from 'routers/PermissionRoute';
import PrivateDashboard from 'routers/PrivateRoute';
import PublicRoute from 'routers/PublicRoute';
import { Loadable } from 'utils/ComponentLoader';
import { routes } from 'utils/routes';
import './assets/fontello/css/mdoc.css';
import './styles/globals.css';

const AdminsPage = Loadable(() => import('pages/admins/AdminsPage'));

const LoginPage = Loadable(() => import('pages/authentication/LoginPage'));
const ForgotPasswordPage = Loadable(
  () => import('pages/authentication/forgotPassword/ForgotPasswordPage'),
);
const ResetPasswordPage = Loadable(
  () => import('pages/authentication/forgotPassword/ChangePasswordPage'),
);
const ProgramsPage = Loadable(() => import('pages/programs/ProgramsPage'));
const OutliersPage = Loadable(() => import('pages/outliers/OutliersPage'));
const CoachPage = Loadable(() => import('pages/coaches/CoachPage'));
const DashboardPage = Loadable(() => import('pages/dashboard/DashboardPage'));
const DashboardComparePage = Loadable(() => import('pages/dashboard-compare/DashboardComparePage'));
const MembersPage = Loadable(() => import('pages/members/MembersPage'));
const SingleMembersPage = Loadable(() => import('pages/single-members-page/SingleMemberPage'));
const SingleCoachPage = Loadable(() => import('pages/single-coach-page/SingleCoachPage'));
const SingleProgramPage = Loadable(() => import('pages/single-program-page/SingleProgramPage'));
const CreateProgramPage = Loadable(() => import('pages/create-program-page/CreateProgramPage'));
const UpdateProgramPage = Loadable(() => import('pages/update-program-page/UpdateProgramPage'));
// const Report = Loadable(() => import('pages/report/Report'));
const SubscriptionsPage = Loadable(() => import('pages/subscriptions/SubscriptionsPage'));
const RolesAndPrivilegesPage = Loadable(
  () => import('pages/roles-and-privileges/RolesAndPrivilegesPage'),
);
const BLAFilter = Loadable(() => import('pages/bla-filter/BLAFilter'));

const AddRolePage = Loadable(() => import('pages/addRole/AddRolePage'));
const ProfileChangePasswordPage = Loadable(() => import('pages/profile/ProfileChangePasswordPage'));
const ProfilePage = Loadable(() => import('pages/profile/ProfilePage'));
const PrivacySettingsPage = Loadable(() => import('pages/settings/PrivacySettingsPage'));
const SettingsLayout = Loadable(() => import('pages/settings/components/SettingsLayout'));
// const HealthDiarypage = Loadable(
//   () => import('pages/single-members-page/HealthDiary/HealthDiarypage'),
// );

const HealthDiaryWeightPage = Loadable(() => import('pages/health-diary/HealthDiaryWeightpage'));
const HealthDiaryHeightPage = Loadable(() => import('pages/health-diary/HealthDiaryHeightPage'));
const HealthDiaryBMIPage = Loadable(() => import('pages/health-diary/HealthDiaryBMIPage'));
const HealthDiaryExercisePage = Loadable(
  () => import('pages/health-diary/HealthDiaryExcercisePage'),
);
const HealthDiaryPregnancyPage = Loadable(
  () => import('pages/health-diary/HealthDiaryPregnancyPage'),
);
const HealthDiaryPSALevelpage = Loadable(() => import('pages/health-diary/HealthDiaryPSALevel'));
const HealthDiaryBloodPressurePage = Loadable(
  () => import('pages/health-diary/HealthDiaryBloodPressurePage'),
);
const HealthDiaryMedicationPage = Loadable(
  () => import('pages/health-diary/HealthDiaryMedicationPage'),
);
const HealthDiaryCholesterolPage = Loadable(
  () => import('pages/health-diary/HealthDiaryCholesterolPage'),
);
const HealthDiaryWaistCircumferencePage = Loadable(
  () => import('pages/health-diary/HealthDiaryWaistCircumferencePage'),
);
const HealthDiaryBloodSugarPage = Loadable(
  () => import('pages/health-diary/HealthDiaryBloodSugarPage'),
);
const HealthConditionsHistoryPage = Loadable(
  () => import('pages/health-passport/HealthConditionsHistoryPage'),
);
const MedicationsHistoryPage = Loadable(
  () => import('pages/health-passport/MedicationsHistoryPage'),
);
const EyeExamHistoryPage = Loadable(() => import('pages/health-passport/EyeExamHistoryPage'));
const FootExamHistoryPage = Loadable(() => import('pages/health-passport/FootExamHistoryPage'));
const ProceduresHistoryPage = Loadable(() => import('pages/health-passport/ProceduresHistoryPage'));
const MyProviderHistoryPage = Loadable(() => import('pages/health-passport/MyProviderHistoryPage'));
const MyLabHistoryPage = Loadable(() => import('pages/health-passport/MyLabHistoryPage'));
const MyPharmacyHistoryPage = Loadable(() => import('pages/health-passport/MyPharmacyHistoryPage'));
const SingleBlaHistory = Loadable(() => import('pages/single-members-page/SingleBlaHistory'));
const ConfigurationPage = Loadable(() => import('pages/configuration/ConfigurationPage'));
const BlogPostsPage = Loadable(() => import('pages/blogs/BlogPostsPage'));
const AddBlogPost = Loadable(() => import('pages/blogs/AddBlogPost'));
const Publication = Loadable(() => import('pages/publication'));
const News = Loadable(() => import('pages/news'));
const AddEditNews = Loadable(() => import('pages/news/AddEditNews'));
const NewsView = Loadable(() => import('pages/news/NewsView'));
const AddEditPublication = Loadable(() => import('pages/publication/AddEditPublication'));
const PublicationView = Loadable(() => import('pages/publication/PublicationView'));
const WebinarsPage = Loadable(() => import('pages/webinars/WebinarsPage'));
const AddEditWebinarPage = Loadable(() => import('pages/webinars/AddEditWebinar'));
const SingleWebinarView = Loadable(() => import('pages/webinars/SingleWebinarView'));
const AppReleasePage = Loadable(() => import('pages/appRelease/AppReleasePage'));
const Career = Loadable(() => import('pages/careers'));
const AddEditCareer = Loadable(() => import('pages/careers/AddEditCareer'));
const HubVisit = Loadable(() => import('pages/hub-visit'));
const SingleHubVisitPage = Loadable(() => import('pages/hub-visit/SingleHubVisitPage'));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routes.HOME}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            </Suspense>
          }
        />
        <Route
          path={routes.FORGOT_PASSWORD}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <ForgotPasswordPage />
              </PublicRoute>
            </Suspense>
          }
        />
        <Route
          path={routes.RESET_PASSWORD}
          element={
            <Suspense fallback={<SkeletonLoader />}>
              <PublicRoute>
                <ResetPasswordPage />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route path="/*" element={<PrivateDashboard />}>
          {/* all dashboard routes here */}
          <Route
            path={routes.DASHBOARD}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <DashboardPage />
              </Suspense>
            }
          />
          <Route
            path={routes.ADMIN}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="assign_role">
                  <AdminsPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.CAREER}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_career">
                  <Career />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HUB_VISIT}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_hubvisit">
                  <HubVisit />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={`${routes.HUB_VISIT}/:id`}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <SingleHubVisitPage />
              </Suspense>
            }
          />

          <Route
            path={routes.ADD_CAREER}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_career">
                  <AddEditCareer />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.PUBLICATION}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_publication_post">
                  <Publication />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.PUBLICATION_ID}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_publication_post">
                  <PublicationView />
                </PermissionRoute>
              </Suspense>
            }
          />

          <Route
            path={routes.ADD_PUBLICATION}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_publication_post">
                  <AddEditPublication />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.NEWS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_publication_post">
                  <News />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.NEWS_ID}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_publication_post">
                  <NewsView />
                </PermissionRoute>
              </Suspense>
            }
          />

          <Route
            path={routes.ADD_NEWS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_publication_post">
                  <AddEditNews />
                </PermissionRoute>
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD_COMPARE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <DashboardComparePage />
              </Suspense>
            }
          />
          <Route
            path={routes.BLA_FILTER}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <BLAFilter />
              </Suspense>
            }
          />

          <Route
            path={routes.OUTLIERS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_all_members" byPass>
                  <OutliersPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.MEMBERS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_all_members">
                  <MembersPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.MEMBER}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_all_members">
                  <SingleMembersPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_EYE_EXAM_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <EyeExamHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_FOOT_EXAM_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <FootExamHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_HEALTH_CONDITIONS_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthConditionsHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_LAB_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <MyLabHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_MEDICATIONS_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <MedicationsHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_PROVIDER_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <MyProviderHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_PROCEDURES_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <ProceduresHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_PASSPORT_PHARMACY_HISTORY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <MyPharmacyHistoryPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.BLA_HISTORY_WITH_DATE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <SingleBlaHistory />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_WEIGHT}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryWeightPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_BLOOD_PRESSURE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryBloodPressurePage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_BLOOD_SUGAR}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryBloodSugarPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_CHOLESTEROL}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryCholesterolPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_EXERCISE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryExercisePage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_MEDICATION}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryMedicationPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_BMI}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <HealthDiaryBMIPage />
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_HEIGHT}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <HealthDiaryHeightPage />
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_PREGNANCY}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryPregnancyPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_PSA}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryPSALevelpage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.HEALTH_DIARY_WAIST_CIRCUMFERENCE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity">
                  <HealthDiaryWaistCircumferencePage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.PROGRAMS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_all_programs">
                  <ProgramsPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.PROGRAM}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_program">
                  <SingleProgramPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.CREATE_PROGRAM}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_program">
                  <CreateProgramPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.UPDATE_PROGRAM}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="edit_program">
                  <UpdateProgramPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.COACHES}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_all_coaches">
                  <CoachPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.COACH}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_coach">
                  <SingleCoachPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.CONFIGURATION}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_medication">
                  <ConfigurationPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          {/* <Route
            path={routes.REPORT}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_report">
                  <Report />
                </PermissionRoute>
              </Suspense>
            }
          /> */}

          <Route
            path={routes.SUBSCRIPTIONS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_all_subscriptions">
                  <SubscriptionsPage />
                </PermissionRoute>
              </Suspense>
            }
          />

          <Route
            path={routes.ROLES_PRIVILEGES}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_roles">
                  <RolesAndPrivilegesPage />
                </PermissionRoute>
              </Suspense>
            }
          />

          <Route
            path={routes.ADD_ROLE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="assign_role">
                  <AddRolePage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.BLOGS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_blog_post">
                  <BlogPostsPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.ADD_BLOG}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_blog_post">
                  <AddBlogPost />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.EDIT_BLOG}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_blog_post">
                  <AddBlogPost />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.WEBINARS}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_webinar_post">
                  <WebinarsPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.ADD_WEBINAR}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_webinar_post">
                  <AddEditWebinarPage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.SINGLE_WEBINAR}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="create_webinar_post">
                  <SingleWebinarView />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path={routes.APP_RELEASE}
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_report">
                  <AppReleasePage />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <PermissionRoute privilege="view_member_activity" byPass>
                  <Navigate to="/settings/profile" />
                </PermissionRoute>
              </Suspense>
            }
          />
          <Route
            path="settings/profile"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <SettingsLayout>
                  <ProfilePage />
                </SettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="settings/privacy"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <SettingsLayout>
                  <PrivacySettingsPage />
                </SettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="settings/change-password"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <SettingsLayout>
                  <ProfileChangePasswordPage />
                </SettingsLayout>
              </Suspense>
            }
          />
        </Route>
      </Routes>
      <NotificationContainer />
    </BrowserRouter>
  );
}

export default App;
